import { createAsyncThunk ,createSlice} from "@reduxjs/toolkit";
import api from "../api"
import { setLocalStorage } from "../utils/local-storage";
import Notify from "../utils/Notify";


const initialState = {
    data:[],
    loading:false,
    my_loans:{}
}

export const calculateLoan = createAsyncThunk(
    "loans/calcuate", async (payload, thunkAPI) => {
        try {
            return await api.calculate(payload)
        }catch(e){
            return thunkAPI.rejectWithValue(e.response.data)
        }
    }
)



export const loanCount = createAsyncThunk(
    "loans/count", async (_, thunkAPI)=>{
        try{
            return await api.getLoanCount()
        }catch(e){
            return thunkAPI.rejectWithValue(e.response.data)
        }
    }
)

export const myLoans = createAsyncThunk(
    "loans/myLoans",async (_,thunkAPI)=>{
        try{
            return await api.myLoans()
        }catch(e){
            return thunkAPI.rejectWithValue(e.response.data)
        }
    }
)



export const loanDetails = createAsyncThunk(
    "loans/loanDetails", async(payload,thunkAPI)=>{
        try{
            return await api.loanDetails(payload)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)            
        }
    }
)

export const apply = createAsyncThunk(
    "loans/apply", async(payload,thunkAPI)=>{
        try{
            return await api.apply(payload)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)            
        }
    }
)

export const pay = createAsyncThunk(
    "loans/loan-details", async(payload,thunkAPI)=>{
        try{
            return await api.pay(payload)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)            
        }
    }
)

export const newLead = createAsyncThunk(
    "loans/newLead", async(payload,thunkAPI)=>{
        try{
            return await api.newLead(payload)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)            
        }
    }
)

export const uploadDocuments = createAsyncThunk(
    "loans/upload-documents", async (payload, thunkAPI) => {
        try {
            return await api.uploadDocuments(payload)
        }catch(e){
            return thunkAPI.rejectWithValue(e.response.data)
        }
    }
)



const loansSlice = createSlice({
    name:"loans",
    initialState,
    extraReducers: (builder)=>{
        //Loan calculation
        builder.addCase(calculateLoan.fulfilled,(state, action)=>{
            //set the calculation response to the store
            state.loading = false
            state.application_results = action?.results
        })

        builder.addCase(calculateLoan.pending,(state, action)=>{
            state.loading = true
        })

        builder.addCase(calculateLoan.rejected,(state, action)=>{
            state.loading = false
        })

        //Uploading Documents
        builder.addCase(uploadDocuments.fulfilled,(state, action)=>{
            //set the calculation response to the store
            state.loading = false
            state.application_results = action?.results
        })

        builder.addCase(uploadDocuments.pending,(state, action)=>{
            state.loading = true
        })

        builder.addCase(uploadDocuments.rejected,(state, action)=>{
            state.loading = false
        })

        //New lead
        builder.addCase(newLead.fulfilled,(state, action)=>{
            //set the calculation response to the store
            state.loading = false
            if(action?.payload?.success === true){
                state.my_loans = action?.payload?.data
            }else if(action?.payload?.success === false){
                Notify({status: 421, message: action?.payload?.error})
            }
        })

        builder.addCase(newLead.pending,(state, action)=>{
            state.loading = true
        })

        builder.addCase(newLead.rejected,(state, action)=>{
            state.loading = false
        })


        //My Loans
        builder.addCase(myLoans.fulfilled,(state, action)=>{
            //set the calculation response to the store
            // state.my_loans = action?.payload?.data

            state.loading = false
            if(action?.payload?.success === true){
                state.my_loans = action?.payload?.data
                console.log("My loans is : " ,action?.payload?.data)
            }else if(action?.payload?.success === false){
                Notify({status: 421, message: action?.payload?.error})
            }
        })

        builder.addCase(myLoans.pending,(state, action)=>{
            state.loading = true
        })

        builder.addCase(myLoans.rejected,(state, action)=>{
            state.loading = false
        })

        
        //Loan Details
        builder.addCase(loanDetails.fulfilled,(state, action)=>{
            //set the calculation response to the store
            state.loading = false
            state.application_results = action?.results
        })

        builder.addCase(loanDetails.pending,(state, action)=>{
            state.loading = true
        })

        builder.addCase(loanDetails.rejected,(state, action)=>{
            state.loading = false
        })


        //loan count
        builder.addCase(loanCount.fulfilled,(state, action)=>{
            //set the calculation response to the store
            state.loading = false
            state.application_results = action?.results
        })

        builder.addCase(loanCount.pending,(state, action)=>{
            state.loading = true
        })

        builder.addCase(loanCount.rejected,(state, action)=>{
            state.loading = false
        })
    }
})

export default loansSlice.reducer