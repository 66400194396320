import './App.css';
import React, { useEffect, useCallback, Suspense } from "react";
import { BrowserRouter,Routes, Route, Link,useNavigate} from "react-router-dom";
import Home from './components/Home';
import Services from './components/Services';
import Contact from './components/Contact';
import About from './components/about'
import LoanCalculator from './components/LoanCalculator';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Verify from './components/auth/Verify';
import ForgotPassword from './components/auth/ForgotPassword';
import Account from './components/dashboard/Account';
import ImportLoans from './components/ImportLoans';
import CheckOffLoans from './components/CheckOffLoans';
import AutoLogbookLoans from './components/AutoLogbookLoans';
import PersonalLoans from './components/PersonalLoans';
import Referral from './components/Referral';
import TermsAndConditions from './components/TermsAndCoditions';
import Dashboard from './components/dashboard';


const ProtectedRoute = React.lazy(() => import("./utils/ProtectedRoute"));


function App() {
  const Logout = () => {
    let navigate = useNavigate();
    const out = useCallback(() => {
      localStorage.clear();   
      navigate("/");
    }, [navigate]);

    useEffect(() => {
      out();
    }, [out]);
    return null;
  };


  return (
      <div className="App w-100 h-100">   
      <BrowserRouter>
        <Suspense fallback={<p> Loading ... </p>}>
          <Routes>
            <Route path={"/auth"} element={<Login />}></Route>
            <Route path={"/register"} element={<Register />}></Route>
            <Route path={"/forgot-password"} element={<ForgotPassword />}></Route>
            <Route path={"/verify"} element={<Verify />}></Route>
            <Route path={"/import-loans"} element={<ImportLoans />}></Route>
            <Route path={"/checkoff-loans"} element={<CheckOffLoans />}></Route>
            <Route path={"/autologbook-loans"} element={<AutoLogbookLoans/>}></Route>
            <Route path={"/personal-loans"} element={<PersonalLoans/>}></Route>
            <Route path={"/"} element={<Home/>}></Route>
          
            <Route path={"/contact"} element={<Contact/>}></Route>
            <Route path={"/referal"} element={<Referral/>}></Route>

            <Route path={"/services"} element={<Services/>}></Route>

            <Route path={"/about"} element={<About/>}></Route>
            <Route path={"/terms-and-conditions"} element={<TermsAndConditions />}></Route>
            <Route path={"/loan-calculator"} element={<LoanCalculator />}></Route>
            <Route path={"/account"} element={<ProtectedRoute><Account/></ProtectedRoute>}></Route>
            <Route path={"/dashboard"} element={<Dashboard/>}></Route>
  
            <Route path={"/logout"} element={<Logout />} />
                
          </Routes>
        </Suspense>
      </BrowserRouter>    
      </div>
  );
}

export default App;
