import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getFromLocalStorage } from "../utils/local-storage";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { calculateLoan, uploadDocuments, newLead } from "../store/loan.store"
import Header from "./Header";
import Slider from '@mui/material/Slider';
import Footer from "./Footer";
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

const LoanCalculator = () => {
  const dispatch = useDispatch();
  const user = getFromLocalStorage("user");
  const navigate = useNavigate();
  const [loanResult, setLoanResult] = useState([]);
  const [rangeValue, setRangeValue] = useState(50000);
  const [installments, setInstallmentsValue] = useState(1);
  const [loanInstallmentAmount, setLoanInstallmentAmount] = useState(0);
  const inputRef = useRef(null);

  const loading = useSelector((state) => state.loans.loading);

  console.log("The state of loading is ", loading)

  const [id_back, setIdBack] = useState(null);
  const [id_front, setIdFront] = useState(null);
  const [mpesa_statement, setMpesaStatement] = useState(null);
  const [bank_statement, setBankStatement] = useState(null);
  const [kra_pin, setKraPin] = useState(null);
  const [period_in_months, setPeriodInMonths] = useState(null);
  const [amount_requested, setAmountRequested] = useState(null);
  const [till_paybill, setPaybillTill] = useState(null);
  const [loanType, setLoanType] = useState('logbook_loan');
  const [maxDuration, setMaxDuration] = useState(24);



  const handleLoanType = (e) => {
    setLoanType(e.target.value);
    if (e.target.value === 'asset_finance') {
      setMaxDuration(48)
    } else if (e.target.value !== 'asset_finance') {
      setMaxDuration(24)
    }

  }


  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('requested_amount', amount_requested);
    formData.append('payment_period', period_in_months);
    formData.append('kra_pin', kra_pin);
    formData.append('mpesa_statement', mpesa_statement); // Use the first file from the array
    formData.append('bank_statement', bank_statement); // Use the first file from the array
    formData.append('id_front', id_front); // Use the first file from the array
    formData.append('id_back', id_back);
    formData.append('user_id', user?.id)
    formData.append('till_paybill', till_paybill)


    dispatch(uploadDocuments(formData)).then((result) => {
      if (result?.payload?.success === true) {

      }
      console.log(result)
    })

  }

  function calculateMonthlyPayment(principal, months, loan_type) {
    let car_track = 3000
    let interest = 0
    let monthlyPayment = 0
    let credit_life_insurance = 0.25


    //Product details, maximum  durations, interest rates
    const products = {
      "logbook_loan": {
        "maximum_duration": "24",
        "monthly_interest": {
          "1": "10",
          "2-6": "5.5",
          "7-12": "5",
          "13-24": "3"
        }
      },
      "import_loan": {
        "maximum_duration": "24",
        "monthly_interest": {
          "1": "10",
          "2-6": "5.5",
          "7-12": "5",
          "13-24": "3"
        }
      },

      "asset_finance": {
        "maximum_duration": "48",
        "monthly_interest": {
          "1": "10",
          "2-6": "5.5",
          "7-12": "5",
          "13-48": "3"
        }
      },
    }


    //Getting the interest rates based on the loan type
    months == 1 ? interest = products[loan_type].monthly_interest["1"] :
      months >= 2 && months <= 6 ? interest = products[loan_type].monthly_interest["2-6"] :
        months >= 7 && months <= 12 ? interest = products[loan_type].monthly_interest["7-12"] :
          months >= 13 && months <= 48 ? interest = products[loan_type].monthly_interest["13-24"] :

            interest = parseFloat(interest)
    principal = parseFloat(principal)
    car_track = parseFloat(car_track)
    months = parseFloat(months)

    //Calculation logic
    //principal * interest/100 + car_track + credit_life_insurance
    monthlyPayment = (principal * (interest / 100))
    monthlyPayment = monthlyPayment + (principal * (credit_life_insurance / 100)) + car_track
    monthlyPayment = (principal + (monthlyPayment * months)) / 12
    return monthlyPayment.toFixed(2);

  }



  const handleRangeChange = (event) => {
    setRangeValue(event.target.value);
    // Use the updated value of rangeValue after setRangeValue has been processed
    setLoanInstallmentAmount((prevLoanInstallmentAmount) => {
      var monthlyPayment = calculateMonthlyPayment(event.target.value, installments, loanType);
      return monthlyPayment;
    });
  };


  const handleInstallmentsChange = (event) => {
    setInstallmentsValue(event.target.value);
    // Use the updated value of rangeValue after setRangeValue has been processed
    setLoanInstallmentAmount((prevLoanInstallmentAmount) => {
      var monthlyPayment = calculateMonthlyPayment(rangeValue, event.target.value, loanType);
      return monthlyPayment;
    });
  };


  const handleNewLead = (values) => {
    dispatch(newLead(values)).then((result) => {
      if (result?.payload?.success === true) {
        navigate("/register")
      }
    })
  }


  return (
    <div style={{ backgroundColor: "#232321" }}>
      <div className="item item-9">
        <Header />
        <div>
        </div>
        <div className="img-fill calculator-1 px-5 pb-0">

          <div className={`row ${loading ? '' : 'd-none'}`} style={{
            position: "absolute",
            top: ".2cm",
            left: "25%",
            zIndex: "1000",
            background: 'rgba(12, 10, 10, 0.5)'
          }}>
            <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
              <h6 className="text-dark d-block col-12 text-muted">Uploading documents....</h6>
              <p className="d-block col-12">
                <CircularProgress color="info" className="d-block" />
              </p>
            </Stack>
          </div>
          <div className="bg-light" style={{ marginBottom: "2cm" }}>
            <div className={`service_heading  ${user ? '' : 'd-none'}`}>
              <div className="" >
                <div className="row justify-content-between">
                <div className="col-md-6 px-5 ">
                    <div className="row">
                      <div className="col-md-6">
                        <p style={{ fontSize: ".4cm", textAlign: "start", lineHeight: "20px" }}
                          className="mb-5 mt-3 txt text-dark"> Calculate your projected payments for various loan
                          <br />sums , interest rates, and durations using this
                          <br />Straight forward Loan Payment Estimator.</p>
                      </div>


                      <div className="floating-label mt-3 col-md-6 mb-4">
                        <small>Select Loan Type</small>
                        <select as="select" className="form-control" id="loanType" name="loan_product_id"
                          onChange={(e) => handleLoanType(e)}>
                          <option value="logbook_loan">LogBook Loan</option>
                          <option value="import_loan">Import Loan</option>
                          <option value="asset_finance">Asset Finance</option>
                        </select>
                      </div>
                    </div>

                    <form className="form-group user_form">
                      <label htmlFor="rangeInput" style={{ display: 'flex', justifyContent: 'space-between', fontSize: '1.2em', fontWeight: 'bold' }}>
                        Loan Amount <span style={{ color: '#a4c639' }}><h5 className="text-muted">

                          <input type="number" name="rangeInput" value={rangeValue} style={{ width: "4cm" }} onChange={handleRangeChange} />
                        </h5></span>
                      </label>
                      <div className="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p style={{ fontSize: '14px', color: '#333' }} className=" text-muted mr-3"><strong>Kes 50,000</strong></p>

                        <Slider
                          size="large"
                          defaultValue={50000}
                          valueLabelDisplay="auto"
                          className="me-5 w-100"
                          step={1000}
                          max={5000000}
                          min={50000}
                          value={rangeValue}
                          onChange={handleRangeChange}
                          style={{ color: '#00bfba' }}
                        />

                        <p style={{ fontSize: '14px', color: '#333' }} className="ml-3 text-muted"><strong>Kes 5,000,000</strong></p>
                      </div>

                      <label htmlFor="rangeInput" className="" style={{ display: 'flex', justifyContent: 'space-between', fontSize: '1.2em', fontWeight: 'bold' }}>
                        Number Of Installments <span style={{ color: '#a4c639' }}><h5 className="text-muted" >
                          <input type="number" name="installmmentsInput" onChange={handleInstallmentsChange} value={installments} style={{ width: "4cm" }} />
                        </h5></span>
                      </label>
                      <div className="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p style={{ fontSize: '14px', color: '#333' }} className=" text-muted mr-3"><strong>1 month</strong></p>


                        <Slider
                          defaultValue={1}
                          valueLabelDisplay="auto"
                          className="me-5 w-100"
                          step={1}
                          max={maxDuration}
                          min={1}
                          value={installments}
                          onChange={handleInstallmentsChange}
                          style={{ color: '#00bfba' }}
                        />
                        <p style={{ fontSize: '14px', color: '#333' }} className=" text-muted ml-4"><strong>{maxDuration} months</strong></p>
                      </div>

                      <div className="row">
                        <label className="" style={{ fontSize: '18px', fontWeight: 'bold', alignItems: "start" }}>
                          Loan Installment Amount <span style={{ color: '#ff8b00' }}><h5 className="text-muted" >
                            <span className="btn text-light " style={{ background: "#00bfba", width: "10rem", fontSize: "1rem" }}>{loanInstallmentAmount}</span>/Month
                          </h5></span>
                        </label>

                      </div>



                    </form>
                  </div>
                              
                  <div className="col-md-6" style={{ backgroundColor: "#00bfba" }}>
                    <form style={{ textAlign: "start" }} className="row p-5" onSubmit={handleSubmit}>
                      <div className="form-group col-md-4">
                        <label htmlFor="amount_requested">Loan Amount</label>
                        <input type="number" className="form-control" name="amount_requested" aria-describedby="emailHelp" placeholder="Kes" id='amount_requested'
                          onChange={(e) => setAmountRequested(e.target.value)}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="period_in_months">Period In Months</label>
                        <input className="form-control" type="number" id="period_in_months" name="period_in_months" placeholder="months"
                          onChange={(e) => setPeriodInMonths(e.target.value)}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="kra_pin">KRA pin</label>
                        <input className="form-control" type="text" id="kra_pin" name="kra_pin" placeholder=""
                          onChange={(e) => setKraPin(e.target.value)}
                        />
                      </div>


                      <div className="form-group col-md-4">
                        <label htmlFor="bank_statement">Bank Statement</label>
                        <input className="d-block" type="file" id="bank_statement" name="bank_statement"
                          onChange={(e) => setBankStatement(e.target.files[0])}
                        />
                      </div>


                      <div className="form-group col-md-4">
                        <label htmlFor="mpesa_statement">Mpesa Statement</label>
                        <input className="d-block" type="file" id="mpesa_statement" name="mpesa_statement"
                          onChange={(e) => setMpesaStatement(e.target.files[0])}

                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label htmlFor="id_front">ID FRONT</label>
                        <input className="d-block" type="file" id="id_front" name="id_front"
                          onChange={(e) => setIdFront(e.target.files[0])}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label htmlFor="id_back">ID BACK</label>
                        <input className="d-block" type="file" id="id_back" name="id_back"
                          onChange={(e) => setIdBack(e.target.files[0])}
                        />
                      </div>


                      <div className="form-group col-md-6">
                        <label htmlFor="paybill_till">Paybill/Till Statement</label>
                        <input className="d-block" type="file" id="paybill_till" name="paybill_till"
                          onChange={(e) => setPaybillTill(e.target.files[0])}
                        />
                      </div>

                      <button type="submit" className="btn  w-100  mx-auto mt-5"
                       style={{ background: '#ff8b00', color: '#fff', padding: '10px', borderRadius: '5px', marginTop: '40px', cursor: 'pointer' }}
                        disabled={!(id_back || !loading)}
                      >
                        Apply
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className={`service_heading ${!user ? '' : 'd-none'}`}>
              <div className="">
                <div className="row justify-content-between">
                  <div className="col-md-6 px-5 ">
                    <div className="row">
                      <div className="col-md-6">
                        <p style={{ fontSize: ".4cm", textAlign: "start", lineHeight: "20px" }}
                          className="mb-5 mt-3 txt text-dark"> Calculate your projected payments for various loan
                          <br />sums , interest rates, and durations using this
                          <br />Straight forward Loan Payment Estimator.</p>
                      </div>


                      <div className="floating-label mt-3 col-md-6 mb-4">
                        <small>Select Loan Type</small>
                        <select as="select" className="form-control" id="loanType" name="loan_product_id"
                          onChange={(e) => handleLoanType(e)}>
                          <option value="logbook_loan">LogBook Loan</option>
                          <option value="import_loan">Import Loan</option>
                          <option value="asset_finance">Asset Finance</option>
                        </select>
                      </div>
                    </div>

                    <form className="form-group user_form">
                      <label htmlFor="rangeInput" style={{ display: 'flex', justifyContent: 'space-between', fontSize: '1.2em', fontWeight: 'bold' }}>
                        Loan Amount <span style={{ color: '#a4c639' }}><h5 className="text-muted">

                          <input type="number" name="rangeInput" value={rangeValue} style={{ width: "4cm" }} onChange={handleRangeChange} />
                        </h5></span>
                      </label>
                      <div className="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p style={{ fontSize: '14px', color: '#333' }} className=" text-muted mr-3"><strong>Kes 50,000</strong></p>

                        <Slider
                          size="large"
                          defaultValue={50000}
                          valueLabelDisplay="auto"
                          className="me-5 w-100"
                          step={1000}
                          max={5000000}
                          min={50000}
                          value={rangeValue}
                          onChange={handleRangeChange}
                          style={{ color: '#00bfba' }}
                        />

                        <p style={{ fontSize: '14px', color: '#333' }} className="ml-3 text-muted"><strong>Kes 5,000,000</strong></p>
                      </div>

                      <label htmlFor="rangeInput" className="" style={{ display: 'flex', justifyContent: 'space-between', fontSize: '1.2em', fontWeight: 'bold' }}>
                        Number Of Installments <span style={{ color: '#a4c639' }}><h5 className="text-muted" >
                          <input type="number" name="installmmentsInput" onChange={handleInstallmentsChange} value={installments} style={{ width: "4cm" }} />
                        </h5></span>
                      </label>
                      <div className="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p style={{ fontSize: '14px', color: '#333' }} className=" text-muted mr-3"><strong>1 month</strong></p>


                        <Slider
                          defaultValue={1}
                          valueLabelDisplay="auto"
                          className="me-5 w-100"
                          step={1}
                          max={maxDuration}
                          min={1}
                          value={installments}
                          onChange={handleInstallmentsChange}
                          style={{ color: '#00bfba' }}
                        />
                        <p style={{ fontSize: '14px', color: '#333' }} className=" text-muted ml-4"><strong>{maxDuration} months</strong></p>
                      </div>

                      <div className="row">
                        <label className="" style={{ fontSize: '18px', fontWeight: 'bold', alignItems: "start" }}>
                          Loan Installment Amount <span style={{ color: '#ff8b00' }}><h5 className="text-muted" >
                            <span className="btn text-light " style={{ background: "#00bfba", width: "10rem", fontSize: "1rem" }}>{loanInstallmentAmount}</span>/Month
                          </h5></span>
                        </label>

                      </div>



                    </form>
                  </div>

                  <div className="col-md-6" style={{ backgroundColor: "#00bfba" }}>
                    <Formik
                      initialValues={{
                        name: "",
                        email: "",
                        id_no: "",
                      }}
                      onSubmit={handleNewLead}
                    >
                      <Form action="" className="p-5" autoComplete="off">
                        <div className="floating-label mt-3">
                          <Field
                            placeholder="Name"
                            type="text"
                            name="name"
                            id="name"
                            autoComplete="off"
                            className="form-control"
                            required
                          />
                        </div>
                        <div className="floating-label mt-3">
                          <Field
                            placeholder="Email"
                            type="text"
                            name="email"
                            id="email"
                            autoComplete="off"
                            className="form-control"
                          />
                        </div>
                        <div className="floating-label mt-3">
                          <Field
                            placeholder="Phone Number"
                            type="text"
                            name="msisdn"
                            id="phone"
                            autoComplete="off"
                            className="form-control"
                          />
                        </div>
                        <div className="floating-label mt-3">
                          <Field
                            placeholder="Id Number"
                            type="text"
                            name="id_no"
                            id="id_no"
                            autoComplete="off"
                            className="form-control"
                          />
                        </div>


                        <div className="floating-label mt-3">
                          <Field as="select" className="form-control" id="loanType" name="loan_product_id">
                            <option value={loanType}  >{loanType}</option>
                            <option value="logbook_loan">LogBook Loan</option>
                            <option value="import_loan">Import Loan</option>
                            <option value="asset_finance">Asset finance</option>
                          </Field>
                        </div>

                        <button type="submit" style={{ background: '#ff8b00', color: '#fff', padding: '10px', borderRadius: '5px', marginTop: '40px', cursor: 'pointer' }}
                          className="w-100 mx-auto">Apply Now</button>
                      </Form>

                    </Formik>

                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>


      </div>
      <Footer />



    </div>
  )
}

export default LoanCalculator;