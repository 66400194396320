import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import {myLoans, loanDetails} from "../../store/loan.store";
import {useSelector,useDispatch} from "react-redux";



function MyLoans() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const my_loans = useSelector((state) => state.loans.my_loans);
  const loan_details = useSelector((state) => state?.cashmania_games);  
  const user = useSelector((state)=>state?.users?.user)


  const loanHistory = my_loans;


  const handleLoanClick = (id) =>{
      //Loan Details
      dispatch(loanDetails(id)).then((res)=>{
        // console.log("The results from fecthing loan details is ", res)
      })      

  }


  useEffect(() => {
    // Fetch myloans
    dispatch(myLoans()).then((res)=>{
      // console.log("The results from fetching my loans is ", res)
    })

  }, []); // Run once on component mount

  return (
    <div className='loans'>
    <Container className="mb-4">
      <h4 className="mb-4 mt-5"><strong>My Loans</strong></h4> 
      
      {/* Loan Count */}
      <Card className="mb-4 shadow">
        <Card.Body>
          <Card.Title>Loan Count</Card.Title>
          <Card.Text>
            <p>Total Loans: {loanHistory.length > 1 ? loanHistory.length : "0"}</p>
          </Card.Text>
        </Card.Body>
      </Card>

      {/* My Loans */}
      <Card className="mb-4 shadow">
        <Card.Body>
          <Card.Title>My Loans</Card.Title>
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Applicant Type</th>
                <th>Amount Requested</th>
                {/* <th>Approved amount</th> */}
                <th>Payment Period</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {loanHistory.length > 1 ? (loanHistory.map((loan) => (
                <tr key={loan.id}>
                  <td>{loan.id}</td>
                  <td>{loan.applicant_type}</td>
                  <td>{loan.requested_amount}</td>
                  {/* <td>{loan.approved_amount}</td> */}
                  <td>{loan.payment_period}</td>
                  <td>{loan.status}</td>
                </tr>
              ))):(<div>No loans available....</div>)}
            </tbody>
          </table>
        </Card.Body>
      </Card>

      {/* Loan Details */}
      {/* <Card className="mb-4 shadow">
        <Card.Body>
          <Card.Title>Loan Details</Card.Title>
        </Card.Body>
      </Card> */}
    </Container>
    </div>
  );
}

export default MyLoans;