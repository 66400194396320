import { Link, useNavigate } from "react-router-dom";
import {useState } from "react";
import {useSelector,useDispatch} from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { sendOtp } from "../../store/users.store";
import { ToastContainer } from "react-toastify";



const Verify = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const loading = useSelector((state)=>state.users.loading);

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // height: '100vh',
    marginBottom:"3cm"
  };

  const formStyle = {
    width: '350px',
    padding: '20px',
    background: 'white',
    // boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    textAlign:"left"

  };

  const formGroupStyle = {
    marginBottom: '20px',
    textAlign:"left"
  };


  const toggleShowPassword = () =>{
    setShowPassword(!showPassword);
  }

  const handleFormSubmit = (values, {setSubmitting}) =>{
    console.log("The data from the login form are : ", values)

      dispatch(sendOtp(values)).then((result)=>{
        console.log("The result from the store action is ", result)
        if(result?.payload?.success === true){
          navigate("/forgot-password")
        }
        
      })

      setSubmitting(false)
  }


  return (
    <div className="item item-13">

  
        <div className="img-fill row">
        
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <Link className="navbar-brand" to="/">
                <img
                src="./assets/images/logo.png"
                alt="quick sava logo"
                style={{width: "8cm"}}
                />
              </Link>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item active">
                    {/* <Link to="/register" className="nav-link">Register</Link> */}
                  </li>
                </ul>
              </div>
            </div>
          </nav>
      
        
          <div className="col-md-6 col-sm-12 service_heading">
          <h3><strong style={{fontFamily:"trueno"}} className="">Recover Your Account</strong></h3>

            <div style={containerStyle}>
              <div className="auth service_heading" style={formStyle}>
                <p className="text-dark">Enter your phone number to receive a verification code</p>
                <Formik
                initialValues={{                  
                  phone_no:""
                }}

                onSubmit={handleFormSubmit}
                >

                <Form>
                  <div style={formGroupStyle}>
                    <label htmlFor="exampleInputEmail1 mt-0">Phone Number</label>
                    <Field type="text" name="phone_no" className="form-control" id="exampleInputEmail1" placeholder="+254*********" />
                  </div>

                  <button type="submit" className="btn  w-100 text-light"
                  disabled={loading}
                  style={{ borderRadius:"10px", backgroundColor:"#ff8b00",fontSize:".6cm"}} 
                  >Send Otp</button>
                  
                </Form>
                </Formik>

              </div>
            </div>


          </div>
          
        </div>
   
      <ToastContainer style={{zIndex:"1", position: "absolute", top:"100px"}}/>
    </div>
  );
}

export default Verify;
