import Header from "./Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getFromLocalStorage } from "../utils/local-storage";
import Footer from "./Footer";



const About = () => {
  const user = getFromLocalStorage("user");
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div style={{ backgroundColor: "#232321" }}>
      <div className="item item-7">
        <Header />
        <div className="img-fill">
          <section className="" id="">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-heading service_heading text-light">

                    <h4 style={{ fontSize: "3em", letterSpacing: "-0.03em", font: "trueno" }} className="mb-0 mt-0 my-3">ABOUT US</h4>
                    <h3 style={{ fontSize: "1.5em", letterSpacing: "0.05em", fontWeight: "normal" }} className="my-4">OUR SOLID BACKGROUND IN FINANCE</h3>

                    <h4 style={{ fontSize: "2em", letterSpacing: "-0.03em" }} className="mt-2">GET TO KNOW ABOUT OUR COMPANY</h4>

                    <h3 style={{ fontSize: ".6cm", letterSpacing: "0.05em", fontWeight: "normal", marginTop: "1cm" }} className="mb-0">
                      Quicksava Ltd is a trusted and reliable non-bank financial services provider based in the vibrant heart of Kenya.
                      <br />With a firm commitment to helping individuals achieve their financial goals
                    </h3>

                    <h3 style={{ fontSize: ".6cm", letterSpacing: "0.05em", fontWeight: "normal", marginTop: "1cm" }} className="">
                      Log Book Loans - At QuickSava, we understand that life can be unpredictable, and sometimes you need quick access to funds.Our Log Book Loans provide a hassle-free solution.
                      <br />
                      Introducing the Quick Import Finance Loan: Your Gateway to Overseas Vehicle Imports In today's globalised world, the desire for unique and high-quality vehicles often leads us to explore options beyond our local dealerships.
                      <br />
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer/>  

    </div>
  )

}

export default About;
