import client from "./client";

export default{
    async getLoanCount(){
        return client.parseResponse(await client.get("/loans/get/count"))
    },

    async myLoans(){
        return client.parseResponse(await client.post("/loans/user-loans",{"user_id":1}))
    },

    async loanDetails(data){
        return client.parseResponse(await client.get("/loans/details/"+data))
    },

    async apply(data){
        return client.parseResponse(await client.post("/loans/apply",data))
    },

    async pay(data){
        return client.parseResponse(await client.post("/loans/pay",data))
    },

    async calculate(data){
        return client.parseResponse(await client.post("/loans/calculate",data))
    },

    async uploadDocuments(data){
        return client.parseResponse(await client.post("/loans/upload-documents",data))
    },

    async newLead(data){
        return client.parseResponse(await client.post("/loans/new-lead",data))
    }


}