import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { Link ,useNavigate} from "react-router-dom";
import { getFromLocalStorage } from "../utils/local-storage";
import Header from './Header';
import { Briefcase } from 'react-bootstrap-icons';
import { Person } from 'react-bootstrap-icons';
import { Bag, FileText,People, Building } from 'react-bootstrap-icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';



const CheckOffLoans = () => {
  const user = getFromLocalStorage("user"); 
  const navigate = useNavigate();

  return(
    <div className='item item-1'>      
      <Header />
      <div className="more-info about-info m-0 p-0">
        <div className=" w-100">         
            
              
                <div className="row justify-content-between">


              <div className="col-md-6">
                <div className=" rounded p-3">

                  <div>
                  
                  <h1 className="text-center  text-light right-content">Check Off <em>Loans</em></h1>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <LazyLoadImage
                      src="./assets/images/turn-off.png"
                      alt="Company Logo"
                      style={{ width: "4cm", marginLeft: "15px" }}
                      effect='blur'
                    />
                    <p style={{ marginLeft: "15px" }}>
                      Streamlined financial solutions with easy approvals, flexible terms, and quick disbursals. Your financial success, checked off effortlessly.
                    </p>
                  </div>
                </div>
                 

                  <div className="card border-0 text-light " style={{backgroundColor:"transparent"}}>
                    <div className="card-body">
                      <h5 className="card-title">Check Off Loan Terms 
                      </h5>
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">2-6 Months</th>
                              <th scope="col">7-12 Months</th>
                              <th scope="col">13-24 Months</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>Maximum Duration (Months)</strong></td>
                              <td>12</td>
                              <td>12</td>
                              <td>12</td>
                            </tr>
                            <tr>
                              <td><strong>Interest Rates</strong></td>
                              <td>5.5%</td>
                              <td>5%</td>
                              <td>3%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <button className='btn btn-success w-100 mt-2 mx-auto text-light' onClick={()=>navigate("/loan-calculator")}>Apply Now !</button>
                    </div>
                  </div>
                </div>
              </div>




                  <div className="col-md-6 p-5 col-sm-12">
                  <div className="container">                
                    <div className="row justify-content-space-between">

                      <div className="col-md-6 mb-4">
                        <div className="card service-card shadow">
                          <div className="card-body">
                          

                            <h2 className='card-title text-success'>Civil Servants 
                            <span className='ml-2'>
                            <People size={29} className='justify-content-end' color="orange" />
                            </span>
                            </h2>
                            <p className='p-0 mb-3 text-start text-dark' style={{ lineHeight: '1.2'}}>
                            Available to civil servants, teachers employed by the TSC, health workers employed by the Government (Doctors, Nurses, Clinical Officers and county government officials)
                              </p>
                            <h5>Requirements</h5>
                            <hr className="bg-success"/>
                            <ul className="list-unstyled">
                            <li>✓ 3 months latest payslips</li>
                               <li>✓ Original PIN and ID</li>
                               <li>✓ Latest 12 months bank and M-Pesa statements</li>
                               <li>✓ You must have a good credit score</li>
                               <li>✓ You must be atleast 18 years old</li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mb-4 col-sm-12">
                        <div className="card service-card shadow">
                          <div className="card-body">
                            <h3 className='card-title text-success'>Private Companies
                            <span className='ml-4'>
                            <Building size={25} className='justify-content-end' color="grey" />
                            </span>
                            </h3>
                            <p className='p-0 mb-3 text-start text-dark' style={{ lineHeight: '1.2'}}>
                            Unsecured personal loan used for personal, family or household purposes
                                 Available to employees of our partner employees
                              </p>
                            <h5>Requirements</h5>
                            <hr className="bg-success"/>
                            <ul className="list-unstyled">
                              <li>✓ 3 months latest payslips</li>
                               <li>✓ Original PIN and ID</li>
                               <li>✓ Latest 12 months bank and M-Pesa statements</li>
                               <li>✓ You must have a good credit score</li>
                               <li>✓ You must be atleast 18 years old</li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12 mb-4">
                        <div className="card service-card shadow">
                          <div className="card-body">
                            <h5 className='card-title text-success'>Asset Based Finance
                            <span className='ml-3'>
                            <Bag size={25} className='' color="red" />
                            </span>
                              
                            </h5>
                            <p className='p-0 mb-3 text-start text-dark' style={{ lineHeight: '1.2'}}>
                              Bussiness, expand MV fleet
                              Individuals ,Purchase Motor Vehicles for Individuals
                              </p>
                            <h5>Requirements</h5>
                            <hr className="bg-success"/>
                            <ul className="list-unstyled">
                              <li>✓ Original vehicle logbook</li>
                              <li>✓ Proforma Invoice from the Dealer</li>
                              <li>✓ Original PIN and ID</li>                           
                              <li>✓ Latest 12 months bank and M-Pesa statements</li>
                              <li>✓ Comprehensive car insurance</li>
                              <li>✓ Post-dated cheques</li>
                              <li>✓ Car valuation</li>
                            </ul>
                          </div>
                        </div>
                      </div>                      
                    </div>
                  </div>


                  </div>

                </div>
              
            
          
        </div>
      </div>
    </div>
    
  )
};

export default CheckOffLoans;