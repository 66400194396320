import React from 'react'
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

function Services() {
    const navigate = useNavigate()
    return (
        <div  style={{backgroundColor:"#232321"}}>
            <div className="item item-6">
                <Header />
                <div className="img-fill">
                    <section className="" id="" >
                        <div className="container products-container">
                            <div className="row services-container">
                                <div className="col-md-12">
                                    <div className="section-heading service_heading text-light">
                                        <h2 className='text-light mb-3 my-2' style={{ fontSize: "1.2cm", letterSpacing: "-0.03em", font: "trueno" }}><strong>OUR PRODUCTS</strong></h2>
                                        <h3 className='my-4'>THESE ARE OUR PRODUCTS AND SERVICES</h3>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-12 ">
                                    <div className="service-item">
                                        <img src="assets/images/quick_drive.png" alt="" />
                                        <div className=" text-light" style={{ height: "10rem", overflow: "hidden", background: "transparent" }}>
                                            <h5 style={{ textAlign: "start", fontWeight: "bolder" }} className='my-2'>Quick LogBook Loans</h5>
                                            <p className="text-light" style={{ lineHeight: "1.2", textAlign: "start" }}>
                                                Quick LogBook loans offer fast, secure financing for urgent needs with your vehicle as collateral. Unlock cash against your vehicle’s logbook.
                                            </p>
                                        </div>
                                        <button onClick={() => navigate("/loan-calculator")} style={{ width: "5cm", fontFamily: "trueno", borderRadius: "10px", backgroundColor: "#ff8b00", fontSize: ".6cm" }} className="apply-button filled-button btn p-2 text-light"><strong>Apply Now</strong></button>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-12 mb-5">
                                    <div className="service-item">
                                        <img src="assets/images/quick_checkoff.png" alt="" />
                                        <div className=" text-light" style={{ height: "10rem", overflow: "hidden", background: "transparent" }}>
                                            <h5 style={{ textAlign: "start", fontWeight: "bolder" }} className='my-2'>Quick CheckOff Loans</h5>
                                            <p className="text-light" style={{ lineHeight: "1.2", textAlign: "start" }}>

                                                Quick Check Off loans offer financial support with manageable deductions from your salary. Achieve your goals without the stress of repayment.
                                            </p>
                                        </div>

                                        <button onClick={() => navigate("/loan-calculator")} style={{ width: "5cm", fontFamily: "trueno", borderRadius: "10px", backgroundColor: "#ff8b00", fontSize: ".6cm" }} className="apply-button filled-button btn p-2 text-light"><strong>Apply Now</strong></button>
                                    </div>
                                </div>


                                <div className="col-lg-3 col-md-12 mb-5">
                                    <div className="service-item">
                                        <img src="assets/images/quick_logbook.png" alt="" />
                                        <div className=" text-light" style={{ height: "10rem", overflow: "hidden", background: "transparent" }}>
                                            <h5 style={{ textAlign: "start", fontWeight: "bolder" }} className='my-2'>Quick Drive Loans</h5>
                                            <p className="text-light" style={{ lineHeight: "1.2", textAlign: "start" }}>
                                                Get the funds you need to import your dream vehicle hassle-free and hit the road in style. Explore global possibilities with Quick Drive.

                                            </p>
                                        </div>
                                        <button onClick={() => navigate("/loan-calculator")} style={{ width: "5cm", fontFamily: "trueno", borderRadius: "10px", backgroundColor: "#ff8b00", fontSize: ".6cm" }} className="apply-button filled-button btn p-2 text-light"><strong>Apply Now</strong></button>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-12 mb-5">
                                    <div className="service-item">
                                        <img src="assets/images/quick_biashara.jpg" alt="" />
                                        <div className=" text-light" style={{ height: "10rem", overflow: "hidden", background: "transparent" }}>
                                            <h5 style={{ textAlign: "start", fontWeight: "bolder" }} className='my-2'>Quick Biashara Loans</h5>
                                            <p className="text-light" style={{ lineHeight: "1.2", textAlign: "start" }}>
                                                Quick Biashara Loans provide immediate solutions for your short-term financial needs. Access funds rapidly to cover emergencies

                                            </p>
                                        </div>
                                        <button onClick={() => navigate("/loan-calculator")} style={{ width: "5cm", fontFamily: "trueno", borderRadius: "10px", backgroundColor: "#ff8b00", fontSize: ".6cm" }} className="apply-button filled-button btn p-2 text-light"><strong>Apply Now</strong></button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer/>


        </div>
    )
}

export default Services;