import { Link } from "react-router-dom";
import { Formik, Form, Field} from "formik";
import { newLead } from "../store/loan.store"
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";


const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNewLead = (values) => {
    dispatch(newLead(values)).then((result) => {
      if (result?.payload?.success === true) {
        navigate("/register")
      }
    })
  }

  return (
    <>
      <footer style={{ backgroundColor: "#232321" }}>
        <div className="container mt-5" id="contact">
          <div className="row">
            <div className="col-md-3 footer-item">
              <h4>
                <Link className="navbar-brand mt-5" href="/">
                  <img
                    src="./assets/images/logo.png"
                    alt="quick sava logo"
                    style={{ width: "4cm" }}
                  />
                </Link>
              </h4>
              <p>Your one stop for loans and money lending schemes  .</p>
              <ul className="social-icons">
                <li><Link rel="nofollow" to="https://www.facebook.com/QuickSavaKE" target="_blank"><i className="fa fa-facebook"></i></Link></li>
                <li><Link href="#"><i className="fa fa-twitter"></i></Link></li>
                <li><Link href="#"><i className="fa fa-linkedin"></i></Link></li>
              </ul>
            </div>
            <div className="col-md-3 footer-item ">
              <h3 className="">Contact</h3>
              <ul className="menu-list">
                <li><Link to="/services">products</Link></li>
              </ul>
            </div>
            <div className="col-md-3 footer-item">
              <h3>Additional Pages</h3>
              <ul className="menu-list">
                <li><Link to="/about">About Us</Link></li>
                {/* <li><Link href="#">Quick Support</Link></li> */}
                <li><Link to="/contact">Contact Us</Link></li>
                <li><Link to="/terms-and-conditions">Terms And Conditions</Link></li>
              </ul>
            </div>
            <div className="col-md-3 footer-item last-item">
              <h3>Contact Us</h3>
              <div className="contact-form">
                <Formik
                   initialValues={{
                    name: "",
                    email: "",
                    id_no: "",
                  }}
                  onSubmit={handleNewLead}                
                >
                <Form id="contact footer-contact" action="" method="post">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <fieldset>
                        <Field name="name" type="text" className="form-control" id="name" placeholder="Name" required />
                      </fieldset>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <fieldset>
                        <Field name="email" type="text" className="form-control" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required />
                      </fieldset>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <fieldset>
                        <Field name="msisdn" type="text" className="form-control" id="msisdn" placeholder="Phone Number" required/>
                      </fieldset>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <fieldset>
                        <Field name="id_no" type="text" className="form-control" id="name" placeholder="Id Number" required="" />
                      </fieldset>
                    </div>


                    <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                      <fieldset>
                          <label>Loan you are interested in</label>
                          <Field as="select"  id="loanType" name="loan_product_id" >
                            <option value="2">Quick LogBook Loan</option>
                            <option value="3">Quick Check Off Loan</option>
                            <option value="2">Quick Drive Loan</option>
                            <option value="3">Quick Biashara Loan</option>
                          </Field>
                      </fieldset>
                    </div>
                 
                    <div className="col-lg-12">
                      <fieldset>
                        <button type="submit" id="form-submit" className="filled-button">Send Details</button>
                      </fieldset>
                    </div>
                  </div>
                </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="sub-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>Copyright &copy; 2023 quicksava Co. Ltd.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer;