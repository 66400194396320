import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import {useSelector,useDispatch} from "react-redux";



function Account() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state)=>state?.users?.user)



  return (
    <div className='account'>
    <Container className="mb-4">
      <h4 className="mb-4 mt-5"><strong>My Account</strong></h4> 
      
      <Card className="mb-4 shadow">
        <Card.Body>
          <Card.Title>User Information</Card.Title>
          <Card.Text>
            <p><strong>Name : </strong> {userData.name}</p>
            <p><strong>Email : </strong>{userData.email}</p>
            <p><strong>Phone Number : </strong> {userData.phone_no}</p>
            <p><strong>Wallet balance:</strong> {userData.wallet_balance}</p>
            <p><strong>Identification Number: </strong>{userData.id_no}</p>
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
    </div>
  );
}

export default Account;