import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { Link,useNavigate } from "react-router-dom";
import { getFromLocalStorage } from "../utils/local-storage";
import Header from './Header';
import { Briefcase } from 'react-bootstrap-icons';
import { Person } from 'react-bootstrap-icons';
import { Bag, FileText } from 'react-bootstrap-icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';



const ImportLoans = () => {
  const user = getFromLocalStorage("user"); 
  const navigate = useNavigate();

  return(
    <div className='item item-1'>          
      <Header />
      

      <div className="more-info about-info m-0 p-0">
        <div className=" w-100">         
            
              
                <div className="row justify-content-between">


              <div className="col-md-6">
                <div className=" rounded p-3">
                  
                <div>          
                <h1 className="text-center text-light right-content">Import <em>Loans</em></h1>              

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <LazyLoadImage
                      src="./assets/images/export.png"
                      alt="Company Logo"
                      style={{ width: "4cm", marginLeft: "15px" }}
                      effect='blur'
                    />
                    <p style={{ marginLeft: "15px" }}>
                    Seamlessly finance your imports with competitive rates, efficient processing, and tailored solutions. Elevate your global trade effortlessly.
                    </p>
                  </div>
                </div>
                 



                  <div className="card border-0 text-light" style={{backgroundColor:"transparent"}}>
                    <div className="card-body">
                      <h5 className="card-title">Import Loan Terms 
                      </h5>
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">2-6 Months</th>
                              <th scope="col">7-12 Months</th>
                              <th scope="col">13-24 Months</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>Maximum Duration (Months)</strong></td>
                              <td>12</td>
                              <td>12</td>
                              <td>12</td>
                            </tr>
                            <tr>
                              <td><strong>Interest Rates</strong></td>
                              <td>5.5%</td>
                              <td>5%</td>
                              <td>3%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <button className='btn btn-success w-100 mt-2 mx-auto text-light mb-5' onClick={()=>navigate("/loan-calculator")}>Apply Now !</button>
                    </div>
                  </div>
                </div>
              </div>




                  <div className="col-md-6 p-5 col-sm-12">
                  <div className="container">                
                    <div className="row justify-content-space-between">

                      <div className="col-md-6 mb-4">
                        <div className="card service-card shadow">
                          <div className="card-body">
                          

                            <h2 className='card-title text-success'>Businesses  
                            <span className='ml-5'>
                            <Briefcase size={29} className='justify-content-end' color="orange" />
                            </span>
                            </h2>
                            <p className='p-0 mb-3 text-start text-dark' style={{ lineHeight: '1.2'}}>
                            The quick Import Finance Loan is designed to cater for Import expenses for motor vehicles from overseas dealers
                              </p>
                            <h5>Requirements</h5>
                            <hr className="bg-success"/>
                            <ul className="list-unstyled">
                              <li>✓ Proforma Invoice + importation documents</li>
                               <li>✓ Director and company pin</li>
                               <li>✓ Certificate of Incorporation</li>
                               <li>✓ Latest 12 months bank and Mpesa statements</li>
                               <li>✓ Atleast 30% deposit to facilitate the end to end process</li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mb-4 col-sm-12">
                        <div className="card service-card shadow">
                          <div className="card-body">
                            <h2 className='card-title text-success'>Individuals
                            <span className='ml-5'>
                            <Person size={29} className='justify-content-end' color="black" />
                            </span>
                            </h2>
                            <p className='p-0 mb-3 text-start text-dark' style={{ lineHeight: '1.2'}}>
                            This is financing to purchase a private use vehicle from an overseas dealer
                              </p>
                            <h5>Requirements</h5>
                            <hr className="bg-success"/>
                            <ul className="list-unstyled">
                                 <li>✓ Proforma + importation Documents</li>                        
                               <li>✓ Original PIN and ID</li> 
                               <li>✓ Latest 12 months bank and M-Pesa statements</li>
                               <li>✓ Atleast 30% deposit to facilitate the end to end process</li>  

                            </ul>
                          </div>
                        </div>
                      </div>
                   
                    </div>
                  </div>


                  </div>

                </div>
              
            
          
        </div>
      </div>
    </div>
    
  )
};

export default ImportLoans;





