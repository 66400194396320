import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';


const TermsAndConditions = () => {
    return (
        <div className='terms item item-20'>
            <Link className="navbar-brand" to="/">
                <img
                    src="./assets/images/logo.png"
                    alt="quick sava logo"
                    style={{ width: "5cm" }}
                />
            </Link>
            <h1 className='mb-4' style={{ color: "#00bfba", fontFamily: "trueno" }}>TERMS AND CONDITIONS</h1>

            <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            <FontAwesomeIcon className="caret_down" icon={faCaretDown} />  1. THE AGREEMENT
                        </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                            <ul>
                                <li>1.1 This agreement sets out the complete terms and conditions (hereinafter called “Terms and Conditions”) which shall be applicable to the Quicksava Enterprise Limited (hereinafter referred to as Quicksava) mobile lending platform.</li>
                                <li>1.2 These Terms and Conditions and any amendments or variations thereto take effect on their date of publication.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            <FontAwesomeIcon className="caret_down" icon={faCaretDown} /> 2. DEFINITION OF TERMS
                        </button>
                    </h2>
                    <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                            <ul className='text-start'>
                                <li>2.1 Borrower means the person at whose request QuickSava will advance a loan facility.
                                    An Agent is an independent party who refers a Borrower to QuickSava through marketing activities by obtaining the borrower's consent, and upon a successful application, there shall be a commission on the value of the sale.</li>
                                <li>2.2 Call Centre means Quicksava’s call center.</li>
                                <li>2.3 Equipment includes your mobile phone handset and/ or other equipment which when used together enables you to access the online lending platform.</li>
                                <li>2.4 LBF means Log Book Finance.</li>
                                <li>2.5 Products mean the various loan facilities offered by Quicksava;</li>
                                <li>2.6 The request means a request and/or instruction received by Quicksava from you or purportedly from you through the system and upon which request Quicksava is authorized to act.</li>
                                <li>2.7 Services shall include any form of financial services or products that Quicksava may offer you pursuant to this Agreement and as you may from time to time subscribe to and “service” shall be construed accordingly.</li>
                                <li>2.8 Security agreement constitutes the legal, valid, binding, and enforceable obligations of the borrower and will continue to be effective security overall and every part of the secured asset.</li>
                                <li>2.9 A secured asset is the asset over which the loan facility is secured.</li>
                                <li>2.10 SMS means a short message service consisting of a text message transmitted from one mobile to another.</li>
                                <li>2.11 System means Quicksava’s electronic lending platform and communications software enabling the borrower to communicate with Quicksava for the purposes of these services.</li>
                                <li>2.12 Transaction fees include the facility fee, rollover fee, loan administration fee, and any other charges payable for the use of the services as published by Quicksava on Quicksava’s website or by such other means as Quicksava shall in its sole discretion determine. Transaction fees are subject to change at any time at Quicksava’s sole discretion.</li>
                                <li>2.13 We, our and us means Quicksava and includes the successors and assigns of the Quicksava.</li>
                                <li>2.14 You and your means the borrower and includes the personal representatives of the Borrower;</li>
                                <li>2.15 USSD T&Cs means these USSD Terms and Conditions.</li>
                                <li>2.16 The word borrower shall include both the masculine and the feminine gender as well as the juristic persons;</li>
                                <li>2.17 Words import the singular meaning where the context so admits includes the plural meaning and vice versa.</li>
                                <li>2.18 Headings in these Terms and Conditions are for convenience purposes only and they do not affect the interpretation of this Agreement.</li>
                            </ul>

                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            <FontAwesomeIcon className="caret_down" icon={faCaretDown} />  3. ACCEPTANCE OF THE TERMS AND CONDITIONS
                        </button>
                    </h2>
                    <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className='accordion-body'>
                            <ul>
                                <li>3.1 Prior to applying for Quicksava's products on our system, it is essential that you thoroughly read and comprehend these Terms and Conditions. </li>
                                <li>3.2 These terms will govern the usage and functionality of our system, as well as the products and services available on it.</li>
                                <li>3.3 Upon understanding, you will be prompted to register on our system.</li>
                                <li>3.4 By registering, you acknowledge that you have read and agreed to our Terms and Conditions, as presented on our official website. </li>
                                <li>3.5 Additionally, by following all prompts and completing a transaction request, it is assumed that you have read, understood, and accepted these Terms and Conditions.</li>
                                <li>3.6 Your utilization of our system and the submission of a request signify your agreement to adhere to and be bound by these Terms and Conditions. </li>
                                <li>3.7 You declare that these Terms and Conditions do not prejudice any other agreements you may have, whether legally or otherwise, regarding the services or products offered.</li>
                                <li>3.8 The Borrower explicitly acknowledges a full understanding of the provisions in this Agreement and voluntary participation for their own benefit. </li>
                                <li>3.9. Acceptance of these Terms and Conditions grants Quicksava authorization to assess your credit score, including accessing your credit history from a registered Credit Reference Bureau.</li>
                                <li>3.10. Quicksava reserves the right to amend or modify these Terms and Conditions periodically. The completion of requests and ongoing use of this service constitutes your agreement to be bound by the terms of any such amendment or variation.</li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                            <FontAwesomeIcon className="caret_down" icon={faCaretDown} />  4. REGISTRATION
                        </button>
                    </h2>
                    <div id="flush-collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className='accordion-body'>
                            <ul>
                                <li>4.1 To access our various products, it is necessary to register by creating an account in our system. You agree to safeguard the confidentiality of your chosen passwords or account identifiers and take responsibility for all activities on your equipment.</li>
                                <li>4.2 Upon signing up or using our system, you confirm having read and comprehended our registration Terms and Conditions on our official website. Your agreement to these Terms and Conditions is implicit in your use of the system.</li>
                                <li>4.3 You explicitly consent to Quicksava obtaining your personal information from the Government of Kenya through the IPRS, and you further authorize the Government of Kenya to disclose this information to Quicksava. Quicksava is granted permission to access any relevant information to evaluate your request and use its credit scoring engine to determine your creditworthiness.</li>
                                <li>4.4 Quicksava may request additional information related to your application, and failure to provide such information within the specified time may result in Quicksava rejecting your loan request. </li>
                                <li>4.5 If you do not accept all these registration Terms and Conditions, it is advised not to proceed with the registration process.</li>
                                <li>4.6 Upon successful registration, it is recommended to thoroughly read and familiarize yourself with the terms and conditions of each product, as you are bound by the specific terms and conditions of each product.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                            <FontAwesomeIcon className="caret_down" icon={faCaretDown} />  5. FEES, CHARGES AND TAXES
                        </button>
                    </h2>
                    <div id="flush-collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className='accordion-body'>
                            <ul>
                                <li>5.1. You hereby agree to cover all fees, commissions, costs, and charges related to your use of our system and/or incurred by Quicksava in securing or attempting to secure settlement of any loan provided, encompassing, but not limited to the following:</li>
                                <ul>
                                    <li>5.1.1. Legal charges, including advocate and client charges, costs, and expenses arising from legal, arbitration, or other proceedings related to your loan account.</li>
                                    <li>5.1.2. Commissions at rates determined by Quicksava periodically.</li>
                                    <li>5.1.3. Various charges and expenses, such as ledger fees, postage, cables, telephone calls, taxes, duties, and impositions.</li></ul>
                                <li>5.2. Repayments under these Terms and Conditions are calculated without considering any taxes you may owe. If taxes are applicable to the repayment, you are required to pay Quicksava an additional amount equivalent to the payment multiplied by the applicable tax rate.</li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                            <FontAwesomeIcon className="caret_down" icon={faCaretDown} />  6. BORROWER'S EQUIPMENT AND BORROWER'S RESPONSIBILITIES
                        </button>
                    </h2>
                    <div id="flush-collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className='accordion-body'>
                            <ul>
                                <li>6.1. You are required to independently provide and upkeep your equipment at your own expense to ensure it operates safely and efficiently for accessing our system. It is your responsibility to guarantee the proper functioning of your equipment. </li>
                                <li>6.2. Quicksava is not accountable for errors or failures resulting from malfunctions in your equipment, nor is it responsible for any issues related to computer viruses while using our system.</li>
                                <li>6.3. Adherence to all instructions, procedures, and terms outlined in these Terms and Conditions, as well as any information or documents provided by Quicksava regarding the system's use, is expected from you.</li>
                                <li>6.4. You acknowledge that the safekeeping and appropriate usage of your equipment are solely your responsibility. It is your duty to prevent unauthorized individuals from gaining possession of your equipment.</li>
                                <li>6.5.  Quicksava bears no liability for losses incurred due to any third party having access to your equipment.</li>
                                <li>6.6. In case you suspect that your equipment has been used fraudulently to complete a transaction without your authorization, you must promptly notify Quicksava through the Call Centre at. However, Quicksava is not liable for any unauthorized infringements in such situations.</li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                            <FontAwesomeIcon className="caret_down" icon={faCaretDown} />   7. EXCLUSION OF LIABILITY
                        </button>
                    </h2>
                    <div id="flush-collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className='accordion-body'>
                            <ul>
                                <li>7.1. Quicksava bears no responsibility for any loss you may incur if the system is disrupted or unavailable due to factors beyond Quicksava's control. These factors include but are not limited to, the failure of your equipment, force majeure, errors, interruptions, delays, or unavailability of our system, as well as circumstances such as terrorist or enemy actions, equipment malfunctions, power loss, adverse weather conditions, atmospheric conditions, and failures in public or private telecommunications systems.</li>
                                <li>7.2. Quicksava is not liable for any losses or damages arising from:</li>
                                <li>7.2.1. Failure, malfunction, interruption, or unavailability of your equipment.</li>
                                <li>7.2.2. Any fraudulent or illegal use of the online lending platform or equipment.</li>
                                <li>7.2.3. Your non-compliance with these Terms and Conditions or any documentation or information provided by Quicksava regarding the use of our system.</li>
                                <li>7.3. All warranties and obligations implied by law are expressly excluded to the maximum extent permitted by law.</li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                            <FontAwesomeIcon className="caret_down" icon={faCaretDown} />  8. INTELLECTUAL PROPERTY RIGHTS
                        </button>
                    </h2>
                    <div id="flush-collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className='accordion-body'>
                            <ul>
                                <li>8.1. You acknowledge that the intellectual property rights for our system (including any modifications, upgrades, or enhancements over time) and all related documentation provided by Quicksava through the system or otherwise belong to Quicksava. </li>
                                <li>8.2. You are prohibited from violating these intellectual property rights, and you may not replicate, reproduce, or manipulate the system and its associated documentation without obtaining Quicksava's prior written consent.</li>

                            </ul>
                        </div>
                    </div>
                </div>


                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                            <FontAwesomeIcon className="caret_down" icon={faCaretDown} />  9. YOUR REQUESTS
                        </button>
                    </h2>
                    <div id="flush-collapseNine" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className='accordion-body'>
                            <ul>
                                <li>9.1. Your equipment requests will be assumed to be initiated by you. You give Quicksava irrevocable authorization to act on any requests it receives from you through the system, holding you responsible for them. </li>
                                <li>9.2. Quicksava has the right to accept and act upon any request, even if incomplete or ambiguous if it believes it can correct the information without needing your input. Even if a request is made in error or fraudulently, Quicksava is considered to have fulfilled its obligations if it has acted in good faith. </li>
                                <li>9.3. Quicksava may, at its discretion, choose not to act on your request until it receives further confirmation or inquiry from you.</li>
                                <li>9.4. You agree to release and indemnify Quicksava from any claims, losses, damages, costs, or expenses arising from its actions in accordance with your requests. </li>
                                <li>9.5. Quicksava is not liable for any transactions, activities, or incidents on your equipment, whether caused by your negligence or not.</li>
                                <li>9.6. Quicksava is authorized to execute orders concerning your loan account as required by court orders or competent authorities under applicable laws. </li>
                                <li>9.7. In case of any conflict between your requests and these Terms and Conditions, these Terms and Conditions take precedence.</li>
                            </ul>
                        </div>

                    </div>
                </div>


            </div>
        </div>
    );
};

export default TermsAndConditions;
