
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createUser } from "../../store/users.store";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ToastContainer } from "react-toastify";


//send otp

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const loading = useSelector((state)=>state.users.loading);
  const [startDate, setStartDate] = useState(new Date());

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // height: '100vh',
    // marginBottom:"3cm"
  };

  const formStyle = {
    width: '350px',
    padding: '20px',
    background: 'white',
    // boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    textAlign:"left"

  };

  const formGroupStyle = {
    marginBottom: '15px',
    marginTop:"-20px",
    marginTop:"0",
    textAlign:"left"
  };

  const toggleShowPassword = () =>{
    setShowPassword(!showPassword);
  }

  const handleFormSubmit = (values, {setSubmitting}) =>{
    console.log("The from the register form are : ", values)

      dispatch(createUser(values)).then((result)=>{
        console.log("The result from the store action is ", result)
        if(result?.payload?.success === true){
          navigate("/auth")
        }

        //if successfull dispatch the otp
      })

      setSubmitting(false)
  }
  return (
    <div className="item item-13">

  
        <div className="img-fill row">
        
          <nav className="navbar navbar-expand-lg" style={{ position: "relative", zIndex: "1000" }}>
            <div className="container">
              <Link className="navbar-brand" to="/">
                <img
                src="./assets/images/logo.png"
                alt="quick sava logo"
                style={{width: "6.5cm"}}
                />
              </Link>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item active">
                    <Link to="/" className="nav-link">Home</Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
      
        
          <div className="col-md-6 col-sm-12 service_heading">

            <div style={containerStyle}>
              <div className="auth service_heading" style={formStyle}>
                <h1><strong style={{
                    fontFamily:"trueno",
                    }}>Sign Up</strong></h1>
                <p className="text-dark">Already have an account ? <Link to="/auth" style={{color:"#ff8b00"}}>Sign in</Link></p>
                <Formik
                initialValues={{
                  email:"",
                  phone_no: '+254',
                  password:""
                }}

                onSubmit={handleFormSubmit}
                >

                <Form>
                  <div className="row">
                    <div style={formGroupStyle} className="col-md-6">                    
                      <Field type="text" name="name" className="form-control" id="exampleInputEmail1" placeholder="Name" style={{borderRadius:"55px"}} required/>
                    </div>

                    <div style={formGroupStyle} className="col-md-6">                    
                      <Field type="text" name="surname" className="form-control" id="exampleInputEmail1" placeholder="Surname" style={{borderRadius:"55px"}} required/>
                    </div>
                  </div>

                  <div className="row">
                    <div style={formGroupStyle} className="col-md-6">                    
                      <Field type="text" name="id_no" className="form-control" id="exampleInputEmail1" placeholder="ID NO" style={{borderRadius:"55px"}} required/>
                    </div>

                    <div style={formGroupStyle} className="col-md-6">                    
                      <Field type="tel" name="phone_no" className="form-control" id="exampleInputEmail1" placeholder="+254" style={{borderRadius:"55px"}} required/>
                    </div>
                  </div>


                  <div style={formGroupStyle}>
                    <Field as="select" name="gender" className="form-control" id="genderSelect" style={{ borderRadius: "55px" }} required>
                    <option value="">Select Gender</option>                      
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </Field>
                  </div>

                  <div style={formGroupStyle}>            
                    <Field type="email" name="email" className="form-control" id="exampleInputPassword" placeholder="Email" style={{borderRadius:"55px"}} required/>
                  </div>

                  <div style={formGroupStyle}>                    
                    <Field type="password" name="password" className="form-control" id="exampleInputPassword1" placeholder="Password" style={{borderRadius:"55px"}} required/>
                  </div>
    
                  <button type="submit" className="btn  w-100 text-light"
                  disabled={loading}
                  style={{ borderRadius:"5px", backgroundColor:"#ff8b00",fontSize:".6cm"}} 
                  ><strong>Sign Up</strong></button>
                  
                </Form>
                </Formik>

              </div>
            </div>


          </div>
          
        </div>
   
      <ToastContainer style={{zIndex:"1", position: "absolute", top:"100px"}}/>
    </div>
  );
}

export default Register;


