import client from "./client";

export default {
    async login(credentials) {
        return client.parseResponse(await client.post('/auth/login', credentials))
    },

    async registerUser(data) {        
        return client.parseResponse(await client.post('/auth/signup', data))
    },

    async resetPassword(data){
        return client.parseResponse( await client.post('/auth/reset_password', data))
    },

    async verificationCode(data){
        return client.parseResponse( await client.post('/auth/send_otp', data))
    },

}
