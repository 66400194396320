import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { getFromLocalStorage } from "../utils/local-storage";
import { Formik, Form, Field } from "formik";
import { newLead } from "../store/loan.store"
import { useDispatch } from "react-redux";



const Contact = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = getFromLocalStorage("user")
  const dispatch = useDispatch();

  const handleNewLead = (values) => {
    dispatch(newLead(values)).then((result) => {
      if (result?.payload?.success === true) {
        navigate("/register")
      }
    })
  }




  function sendEmail(e) {
    e.preventDefault();

    const name = document.querySelector('input[name="name"]').value;
    const phone = document.querySelector('input[name="phone"]').value;
    const email = document.querySelector('input[name="email"]').value;
    const subject = document.querySelector('input[name="subject"]').value;
    const message = document.querySelector('textarea[name="message"]').value;
    const emailLink = `mailto:otitrevor450@gmail.com?subject=${subject}&body=Name: ${name}%0D%0APhone: ${phone}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`;
    if (!name || !phone || !email || !message) {
      alert("Please fill all the fields");
      return;
    }

    window.open(emailLink)
  }


  return (
    <>
      <div className="item item-8">
        <Header />

        <div className="img-fill" style={{ height: "" }}>
          <div className="service_heading">
            <h4 style={{ fontSize: "1.5cm", letterSpacing: "-0.03em", textAlign: "start", font: "trueno" }} className="mb-0 mt-0 ml-5 mt-5 ">CONTACT US</h4>
          </div>
        </div>

        <footer >
          <div className="container mt-5" id="contact">
            <div className="row">
              <div className="col-md-3 footer-item">
                <h4>
                  <Link className="navbar-brand mt-5" to="/">
                    <img
                      src="./assets/images/logo.png"
                      alt="quick sava logo"
                      style={{ width: "4cm" }}
                    />
                  </Link>
                </h4>
                <p>Your one stop for loans and money lending schemes  .</p>
                <ul className="social-icons">
                  <li><Link rel="nofollow" to="https://www.facebook.com/QuickSavaKE" target="_blank"><i className="fa fa-facebook"></i></Link></li>
                  <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                  <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                </ul>
              </div>
              <div className="col-md-3 footer-item ">
                <h3 className="">Contact</h3>
                <ul className="menu-list">
                  {/* <li><a href="#">quicksava</a></li> */}
                  {/* <li><a href="#">Customers</a></li>              */}
                  <li><a href="/services">products</a></li>
                  {/* <li><a href="#">More</a></li> */}
                </ul>
              </div>
              <div className="col-md-3 footer-item">
                <h3>Additional Pages</h3>
                <ul className="menu-list">
                  <li><Link to="/about">About Us</Link></li>
                  {/* <li><a href="#">How We Work</a></li> */}
                  {/* <li><a href="#">Quick Support</a></li> */}
                  <li><Link to="/contact">Contact Us</Link></li>
                  <li><Link to="/terms-and-conditions">Terms And Conditions</Link></li>

                  {/* <li><a href="#">Privacy Policy</a></li> */}
                </ul>
              </div>
              <div className="col-md-3 footer-item last-item">
                <h3>Contact Us</h3>
                <div className="contact-form">
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      id_no: "",
                    }}
                    onSubmit={handleNewLead}
                  >
                    <Form id="contact footer-contact" action="" method="post">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <fieldset>
                            <Field name="name" type="text" className="form-control" id="name" placeholder="Name" required />
                          </fieldset>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <fieldset>
                            <Field name="email" type="text" className="form-control" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required />
                          </fieldset>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <fieldset>
                            <Field name="msisdn" type="text" className="form-control" id="msisdn" placeholder="Phone Number" required />
                          </fieldset>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <fieldset>
                            <Field name="id_no" type="text" className="form-control" id="name" placeholder="Id Number" required="" />
                          </fieldset>
                        </div>


                        <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                          <fieldset>
                            <label>Loan you are interested in</label>
                            <Field as="select" id="loanType" name="loan_product_id" >
                              <option value="2">Quick LogBook Loan</option>
                              <option value="3">Quick Check Off Loan</option>
                              <option value="2">Quick Drive Loan</option>
                              <option value="3">Quick Biashara Loan</option>
                            </Field>
                          </fieldset>
                        </div>

                        <div className="col-lg-12">
                          <fieldset>
                            <button type="submit" id="form-submit" className="filled-button">Send Details</button>
                          </fieldset>
                        </div>
                      </div>
                    </Form>
                  </Formik>

                </div>
              </div>
            </div>
          </div>
        </footer>

      </div>
    </>
  )
}

export default Contact;