import { Link, useNavigate } from "react-router-dom";
import {useState } from "react";
import {useSelector,useDispatch} from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { loginUser } from "../../store/users.store";
import { ToastContainer } from "react-toastify";


const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const loading = useSelector((state)=>state.users.loading);

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // height: '100vh',
    marginBottom:"3cm"
  };

  const formStyle = {
    width: '350px',
    padding: '20px',
    background: 'white',
    // boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    textAlign:"left"

  };

  const formGroupStyle = {
    marginBottom: '20px',
    textAlign:"left"
  };

  const toggleShowPassword = () =>{
    setShowPassword(!showPassword);
  }

  const handleFormSubmit = (values, {setSubmitting}) =>{

      dispatch(loginUser(values)).then((result)=>{
        if(result?.payload?.success === true){
          navigate("/") 
        }        
      })

      setSubmitting(false)
  }


  return (
    <div className="item item-13">

  
        <div className="img-fill row">
        
          <nav className="navbar navbar-expand-lg" style={{ position: "relative", zIndex: "1000" }}>
            <div className="container">
              <Link className="navbar-brand" to="/">
                <img
                src="./assets/images/logo.png"
                alt="quick sava logo"
                style={{width: "8cm"}}
                />
              </Link>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item active">
                    <Link to="/" className="nav-link" >Home</Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
      
        
          <div className="col-md-6 col-sm-12 service_heading">

            <div style={containerStyle}>
              <div className="auth service_heading" style={formStyle}>
                <h1><strong style={{fontFamily:"trueno"}}>Login</strong></h1>
                <p className="text-dark">Don't have an account yet? <Link to="/register" style={{color:"#ff8b00"}}><small>Sign up</small></Link></p>
                <Formik
                initialValues={{
                  email:"",
                  password:""
                }}

                onSubmit={handleFormSubmit}
                >

                <Form>
                  <div style={formGroupStyle}>
                    <label htmlFor="exampleInputEmail1 mt-0">Email address</label>
                    <Field type="email" name="email" className="form-control" id="exampleInputEmail1" placeholder="you@example.com" />
                  </div>
                  <div style={formGroupStyle}>
                    {/* <label htmlFor="exampleInputPassword1">Password <a href="/verify" style={{color:"#ff8b00"}}>Forgot password?</a></label> */}
                    <label htmlFor="exampleInputPassword1">Password &nbsp; &nbsp; &nbsp;<Link to="/verify" style={{color:"#ff8b00",textAlign:"end"}} className="text-end"><small>Forgot password?</small></Link></label>
                    

                    <Field type="password" name="password" className="form-control" id="exampleInputPassword1" placeholder="Enter 6 character or more" />
                  </div>
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                    <label className="form-check-label mb-4" htmlFor="exampleCheck1">Remember me </label>
                  </div>
                  <button type="submit" className="btn  w-100 text-light"
                  disabled={loading}
                  style={{ borderRadius:"10px", backgroundColor:"#ff8b00",fontSize:".6cm"}} 
                  >Login</button>
                  
                </Form>
                </Formik>

              </div>
            </div>


          </div>
          
        </div>
   
      <ToastContainer style={{zIndex:"1", position: "absolute", top:"100px"}}/>
    </div>
  );
}

export default Login;

