import React, { useState, useEffect } from 'react';
import './Sidebar.css'; // Import your CSS file
import Account from './Account';
import { useNavigate } from 'react-router-dom';
import MyLoans from './Loans';
import { faBoxOpen, faHome, faMoneyBill, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';


const Dashboard = () => {
    const [isSidebarClosed, setSidebarClosed] = useState(false);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [activeItem, setActiveItem] = useState('my_account');
    const [sidebarWidth, setSidebarWidth] = useState(260);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate();

    const handleToggleSidebar = () => {
        setSidebarClosed(!isSidebarClosed);
    };

    const handleItemClick = (item) => {
        setActiveItem(item);
    };

    const handleResizeSidebar = (width) => {
        setSidebarWidth(width);
    };

    useEffect(() => {
        // Function to update window width in the state
        const updateWindowWidth = () => {
            setWindowWidth(window.innerWidth);

        };

        // Add event listener for window resize
        window.addEventListener('resize', updateWindowWidth);

        // Initial call to set the initial window width
        updateWindowWidth();

        // Remove event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', updateWindowWidth);
        };
    }, []);


    return (
        <div>
            <div className={`sidebar ${isSidebarClosed ? 'close' : ''}`}>
                <div className="logo-details">
                    <img
                        src="./assets/images/logo.png"
                        alt="quick sava logo"
                        style={{ width: "5cm" }}
                        className={`${isSidebarClosed ? 'd-none' : ''}`}
                    />

                    <i
                        className={`bx ${isSidebarClosed ? 'bx-x' : 'bx-menu'} mx-auto mt-5`}
                        onClick={handleToggleSidebar} style={{ cursor: "pointer", fontSize: ".7cm" }}></i>
                </div>
                <ul className="nav-links">
                <li ><Link to="/"><span className="link_name px-5 py-3" onClick={() => navigate("/")}>
                        <span className={`${isSidebarClosed ? 'd-none' : ''}`}>Home</span>
                        <FontAwesomeIcon icon={faHome} color="" className="mx-2" />

                    </span></Link>
                    </li>
                    <li ><a><span className="link_name px-5 py-3" onClick={() => handleItemClick('my_account')}>
                        <span className={`${isSidebarClosed ? 'd-none' : ''}`}>My Account</span>
                        <FontAwesomeIcon icon={faUser} color="" className="mx-2" />

                    </span></a>
                    </li>
                    <li><a><span className="link_name px-5 py-3" onClick={() => handleItemClick('my_loans')}>
                        <span className={`${isSidebarClosed ? 'd-none' : ''}`}>My Loans</span>

                        <FontAwesomeIcon icon={faMoneyBill} color="" className="mx-2" />

                    </span></a></li>
                    <li><a><span className="link_name px-5 py-3" onClick={() => navigate("/logout")}>
                        <span className={`${isSidebarClosed ? 'd-none' : ''}`}>Logout</span>

                        <FontAwesomeIcon icon={faBoxOpen} color="" className="mx-2" />
                    </span></a></li>

                </ul>
                <div className="profile-details">
                    <div className="profile-content">
                    </div>
                    <i className='bx bx-log-out'></i>
                </div>
                <div className="one"></div>
            </div>
            <div
                style={{ marginLeft: isSidebarClosed && windowWidth > 768 ? '78px' : `${sidebarWidth}px` }}
                className=''>
                {activeItem === 'my_account' && <Account />}
                {activeItem === 'my_loans' && <MyLoans />}
            </div>
        </div>
    );
};

export default Dashboard;
